import React from 'react';

import { CHECK_CIRCLE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';

export interface IProtectionDialogContentItemProps {
  title: React.ReactElement;
  description?: React.ReactElement;
}

export const ProtectionDialogContentItem: React.FC<
  React.PropsWithChildren<IProtectionDialogContentItemProps>
> = ({ title, description }) => {
  return (
    <div className="mt-2 autoType500">
      <div className="flex items-center">
        <Icon name={CHECK_CIRCLE} className="mr-3 text-green-500" width={16} height={16} />
        <span className={`text-primary-base ${description ? 'highlight' : 'semiHighlight'}`}>
          {title}
        </span>
      </div>

      {description && <div className="text-gray-500 ml-7 semiHighlight">{description}</div>}
    </div>
  );
};
