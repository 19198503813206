import React from 'react';
import { useIntl } from 'react-intl';

import { EBillModuleDatesVariants } from './BillModuleDates';
import css from './BillModuleDatesButton.module.css';

interface IProps {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isSelected?: boolean;
  date?: string | Date;
  isUnavailable?: boolean;
  className?: string;
  ['data-testid']?: string;
  variant?: EBillModuleDatesVariants;
}

const BillModuleDatesButton: React.FC<IProps> = ({
  className = '',
  label,
  onClick,
  isSelected,
  date,
  isUnavailable,
  ['data-testid']: testId,
  variant = EBillModuleDatesVariants.large,
}) => {
  const intl = useIntl();
  const formattedDate = date
    ? intl.formatDate(
        date,
        variant !== EBillModuleDatesVariants.small
          ? {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            }
          : {
              day: 'numeric',
              month: 'short',
            },
      )
    : intl.formatMessage({
        defaultMessage: 'Add dates',
        id: '9J3cxw',
        description: 'Button label for calendar opening in Bill module.',
      });

  return (
    <button
      data-variant={variant}
      className={`transition-colors duration-200 ease-in-out ${css.dateButton} ${className} before-focus-style`}
      onClick={onClick}
      data-is-selected={isSelected}
      data-testid={testId}>
      {label && <div className={`text-gray-500 text autoType200 ${css.label}`}>{label}</div>}
      <div className={css.date} data-is-unavailable={isUnavailable}>
        {formattedDate}
      </div>
    </button>
  );
};

export default BillModuleDatesButton;
