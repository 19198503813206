import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EProtections } from '@/services/types/core/protections';

export enum EProtectionLabel {
  TITLE = 'title',
  PURCHASED_TITLE = 'purchased_title',
  MODAL_TITLE = 'modal_title',
  DESCRIPTION = 'description',
  PURCHASED_DESCRIPTION = 'purchased_description',
  NOT_PURCHASED_DESCRIPTION = 'not_purchased_description',
  ADD_MODAL_DESCRIPTION = 'add_modal_description',
  REMOVE_MODAL_DESCRIPTION = 'remove_modal_description',
  ADD_BUTTON_TEXT = 'add_button_text',
  VIEW_OR_CANCEL_BUTTON_TEXT = 'view_or_cancel_button_text',
  VIEW_BUTTON_TEXT = 'view_button_text',
  LISTING_MODAL_DESCRIPTION = 'listing_modal_description',
}

interface IProtectionLabelProps {
  category: EProtections;
  label: EProtectionLabel;
}

export const ProtectionLabel: React.FC<IProtectionLabelProps> = ({ category, label }) => {
  const labels = {
    'trip-insurance': {
      [EProtectionLabel.TITLE]: (
        <FormattedMessage defaultMessage="Cancellation coverage" id="oFjHQK" />
      ),
      [EProtectionLabel.PURCHASED_TITLE]: (
        <FormattedMessage defaultMessage="Cancellation coverage purchased" id="H1/26J" />
      ),
      [EProtectionLabel.MODAL_TITLE]: (
        <FormattedMessage defaultMessage="Cancellation coverage" id="oFjHQK" />
      ),
      [EProtectionLabel.DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Cancellations, delays, and other mishaps come with a cost. This plan helps pay you back."
          id="dXKWAF"
        />
      ),
      [EProtectionLabel.PURCHASED_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Cancellations, delays, and other mishaps come with a cost. Good thing you have this plan to help pay you back."
          id="AUns5B"
        />
      ),
      [EProtectionLabel.NOT_PURCHASED_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Cancellations, delays, and other mishaps come with a cost. This plan helps pay you back."
          id="dXKWAF"
        />
      ),
      [EProtectionLabel.ADD_MODAL_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Pays back some of your upfront costs and certain unexpected expenses if your trip veers off course."
          id="0l1r83"
        />
      ),
      [EProtectionLabel.REMOVE_MODAL_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Pays back some of your upfront costs and certain unexpected expenses if your trip veers off course."
          id="0l1r83"
        />
      ),
      [EProtectionLabel.LISTING_MODAL_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Covers you before and during your trip. Reimburses you up to 100% of nonrefundable payments, in some cases."
          id="wl11ks"
        />
      ),
      [EProtectionLabel.ADD_BUTTON_TEXT]: (
        <FormattedMessage defaultMessage="Add cancellation coverage" id="kQczYX" />
      ),
      [EProtectionLabel.VIEW_OR_CANCEL_BUTTON_TEXT]: (
        <FormattedMessage defaultMessage="View or change coverage" id="k4y50z" />
      ),
      [EProtectionLabel.VIEW_BUTTON_TEXT]: (
        <FormattedMessage defaultMessage="View cancellation coverage" id="5hFthH" />
      ),
    },
    'damage-protection': {
      [EProtectionLabel.TITLE]: (
        <FormattedMessage defaultMessage="Interior damage protection" id="f8usXd" />
      ),
      [EProtectionLabel.PURCHASED_TITLE]: (
        <FormattedMessage defaultMessage="Interior damage protection purchased" id="prDrYF" />
      ),
      [EProtectionLabel.MODAL_TITLE]: (
        <FormattedMessage defaultMessage="Interior damage protection" id="f8usXd" />
      ),
      [EProtectionLabel.DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Enjoy a worry-free trip with protection against interior damage, especially when traveling with kids or pets."
          id="Z25RSH"
        />
      ),
      [EProtectionLabel.PURCHASED_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="This helps with repair costs if you bring back the RV with interior damage."
          id="3Z4hjH"
        />
      ),
      [EProtectionLabel.NOT_PURCHASED_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="This helps with repair costs if you bring back the RV with interior damage."
          id="3Z4hjH"
        />
      ),
      [EProtectionLabel.ADD_MODAL_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="If you bring back the RV with interior damage, some or all of your security deposit may go to repair costs. This helps pay you back, up to the full amount of your security deposit."
          id="I9dD14"
        />
      ),
      [EProtectionLabel.REMOVE_MODAL_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="If you bring back the RV with interior damage, some or all of your security deposit may go to repair costs. This helps pay you back, up to the full amount of your security deposit."
          id="I9dD14"
        />
      ),
      [EProtectionLabel.LISTING_MODAL_DESCRIPTION]: <></>,
      [EProtectionLabel.ADD_BUTTON_TEXT]: (
        <FormattedMessage defaultMessage="Add interior coverage" id="JUI5vl" />
      ),
      [EProtectionLabel.VIEW_OR_CANCEL_BUTTON_TEXT]: (
        <FormattedMessage defaultMessage="View or change coverage" id="k4y50z" />
      ),
      [EProtectionLabel.VIEW_BUTTON_TEXT]: (
        <FormattedMessage defaultMessage="View interior coverage" id="9FhrLK" />
      ),
    },
    weather: {
      [EProtectionLabel.TITLE]: <FormattedMessage defaultMessage="Weather coverage" id="OUiTT1" />,
      [EProtectionLabel.PURCHASED_TITLE]: (
        <FormattedMessage defaultMessage="Weather coverage purchased" id="ZO6ZsT" />
      ),
      [EProtectionLabel.MODAL_TITLE]: (
        <FormattedMessage defaultMessage="Weather by Sensible" id="/NzPaf" />
      ),
      [EProtectionLabel.DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Give yourself peace of mind knowing you’ll be automatically reimbursed when it rains during your trip dates."
          id="zfYGYF"
        />
      ),
      [EProtectionLabel.PURCHASED_DESCRIPTION]: (
        <FormattedMessage defaultMessage="Get reimbursed for bad weather." id="dd/dfh" />
      ),
      [EProtectionLabel.NOT_PURCHASED_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Add a Weather Guarantee and get reimbursed for bad weather."
          id="C6Axun"
        />
      ),
      [EProtectionLabel.ADD_MODAL_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Give yourself peace of mind knowing you’ll be automatically reimbursed up to 100% if there is rain forecast during your trip."
          id="2ZKePR"
        />
      ),
      [EProtectionLabel.REMOVE_MODAL_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Give yourself peace of mind knowing you’ll be automatically reimbursed up to 100% if there is rain forecast during your trip."
          id="2ZKePR"
        />
      ),
      [EProtectionLabel.LISTING_MODAL_DESCRIPTION]: (
        <FormattedMessage
          defaultMessage="Give yourself peace of mind knowing you’ll be automatically reimbursed up to 100% if there is rain forecast during your trip."
          id="2ZKePR"
        />
      ),
      [EProtectionLabel.ADD_BUTTON_TEXT]: (
        <FormattedMessage defaultMessage="Check eligibility" id="4fdfOm" />
      ),
      [EProtectionLabel.VIEW_OR_CANCEL_BUTTON_TEXT]: (
        <FormattedMessage defaultMessage="View or change coverage" id="k4y50z" />
      ),
      [EProtectionLabel.VIEW_BUTTON_TEXT]: (
        <FormattedMessage defaultMessage="View weather coverage" id="38qDec" />
      ),
    },
  };

  return labels[category][label];
};
