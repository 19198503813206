import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import DatePickerModal from '@/components/switchback/DatePickerModal/DatePickerModal';
import { isBookingHandedOff } from '@/redux/modules/booking/selectors';

import css from './BillModuleDates.module.css';
import BillModuleDatesButton from './BillModuleDatesButton';

export enum EBillModuleDatesVariants {
  small = 'small',
  large = 'large',
  medium = 'medium',
}

interface IProps {
  calendarWarning?: string;
  className?: string;
  disableClear?: boolean;
  disabledDays?: {
    from: Date;
    to: Date;
  }[];
  from?: Date;
  isUnavailable?: boolean;
  minimumDays?: number;
  onConfirmDates?: (from?: Date, to?: Date) => void;
  onDismissCalendar?: () => void;
  onSelectDate?: (from?: Date, to?: Date) => void;
  open?: boolean;
  testId?: string;
  to?: Date;
  variant?: EBillModuleDatesVariants;
}

const BillModuleDates: React.FC<IProps> = ({
  calendarWarning,
  className = '',
  disableClear,
  disabledDays,
  from,
  isUnavailable,
  minimumDays = 0,
  onConfirmDates,
  onDismissCalendar,
  onSelectDate,
  open = false,
  testId,
  to,
  variant = EBillModuleDatesVariants.large,
}) => {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const departureTestId = testId ? `${testId}-departure-date-btn` : 'departure-date-btn';
  const arrivalTestId = testId ? `${testId}-arrival-date-btn` : 'arrival-date-btn';
  const [isOpen, setIsOpen] = useState(open);
  const tripStarted = useSelector(isBookingHandedOff);
  const labelFrom = intl.formatMessage({
    defaultMessage: 'Start',
    id: 'A8xZ4m',
    description: 'UI > BillModuleDates > Date picker label for "from" field',
  });
  const labelTo = intl.formatMessage({
    defaultMessage: 'End',
    id: 'dR1ocq',
    description: 'UI > BillModuleDates > Date picker label for "to" field',
  });

  const handleConfirmDates = (from?: Date, to?: Date) => {
    onConfirmDates?.(from, to);
    handleDismissCalendar();
  };

  const handleClearDates = () => {
    onSelectDate?.();
    handleDismissCalendar();
  };

  const handleOpenCalendar = () => setIsOpen(true);
  const handleDismissCalendar = () => {
    setIsOpen(false);
    onDismissCalendar?.();
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div ref={containerRef}>
      <div data-variant={variant} className={`${css.billDate} flex flex-col w-full ${className}`}>
        <div className={css.holder}>
          <BillModuleDatesButton
            variant={variant}
            label={labelFrom}
            isUnavailable={isUnavailable}
            date={from}
            className="border-r"
            isSelected={isOpen && (!from || (!!from && !!to))}
            onClick={handleOpenCalendar}
            data-testid={departureTestId}
          />
          <BillModuleDatesButton
            variant={variant}
            label={labelTo}
            isUnavailable={isUnavailable}
            date={to}
            isSelected={isOpen && !!from && !to}
            onClick={handleOpenCalendar}
            data-testid={arrivalTestId}
          />
        </div>
      </div>
      <DatePickerModal
        disableClear={disableClear}
        disabledDays={disabledDays}
        initialFrom={from}
        initialTo={to}
        minimumDays={minimumDays}
        onConfirmDates={handleConfirmDates}
        onClearDates={handleClearDates}
        onDismiss={handleDismissCalendar}
        onSelectDate={onSelectDate}
        relativeTo={containerRef.current}
        show={isOpen}
        warning={calendarWarning}
        disableFrom={tripStarted || false}
      />
    </div>
  );
};

export default BillModuleDates;
