import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Link from '@/components/switchback/Link/Link';
import {
  IProtectionDialogContentItemProps,
  ProtectionDialogContentItem,
} from '@/components/ui/TripProtection/ProtectionDialogContentItem';
import { EProtections } from '@/services/types/core/protections';

import { EProtectionLabel, ProtectionLabel } from './ProtectionLabel';

interface IProtectionDialogContentProps {
  category: EProtections;
  serviceIsAdded: boolean;
  inBillSection?: boolean;
}

export const ProtectionDialogContent: React.FC<
  React.PropsWithChildren<IProtectionDialogContentProps>
> = ({ category, serviceIsAdded, inBillSection = false, children }) => {
  const items: IProtectionDialogContentItemProps[] =
    category === EProtections.TRIP_INSURANCE
      ? [
          {
            title: (
              <FormattedMessage
                defaultMessage="Cancellations, interruptions, and delays"
                id="uLw4q1"
              />
            ),
            description: (
              <FormattedMessage
                defaultMessage="You have to miss all or some of your trip due to a covered reason."
                id="44teG8"
              />
            ),
          },
          {
            title: <FormattedMessage defaultMessage="Missing necessities" id="0hDYeH" />,
            description: (
              <FormattedMessage
                defaultMessage="There’s a baggage delay, and you need to buy a few things in the meantime."
                id="mlXfz4"
              />
            ),
          },
          {
            title: <FormattedMessage defaultMessage="Emergency transportation" id="IbBVvy" />,
            description: (
              <FormattedMessage
                defaultMessage="Gets you to a medical facility when you need care right away."
                id="07yV0o"
              />
            ),
          },
        ]
      : category === EProtections.DAMAGE_PROTECTION
        ? [
            {
              title: (
                <FormattedMessage defaultMessage="Broken appliances and electronics" id="jX6Lms" />
              ),
            },
            {
              title: (
                <FormattedMessage
                  defaultMessage="Damaged walls, furniture, and doors"
                  id="TVoF4G"
                />
              ),
            },
            { title: <FormattedMessage defaultMessage="Lost keys and rekeying" id="9uFvFV" /> },
            { title: <FormattedMessage defaultMessage="Stained bedding or linens" id="2IlMps" /> },
          ]
        : category === EProtections.WEATHER_PROTECTION
          ? [
              {
                title: (
                  <FormattedMessage
                    defaultMessage="Sensible will monitor the forecast for your trip location."
                    id="57Jo57"
                  />
                ),
              },
              {
                title: (
                  <FormattedMessage
                    defaultMessage="If rain is forecast, you'll be automatically reimbursed. No claims, no hassle."
                    id="iTUsP8"
                  />
                ),
              },
            ]
          : [];

  let label = EProtectionLabel.LISTING_MODAL_DESCRIPTION;
  if (!inBillSection) {
    label = serviceIsAdded
      ? EProtectionLabel.REMOVE_MODAL_DESCRIPTION
      : EProtectionLabel.ADD_MODAL_DESCRIPTION;
  }

  return (
    <div>
      <p className="mb-2 text-gray-800 semiHighlight autoType500">
        <ProtectionLabel category={category} label={label} />
      </p>

      <div className={`${inBillSection ? 'mb-4' : 'mb-6'}`}>
        {items.map(({ title, description }, index) => (
          <ProtectionDialogContentItem key={index} title={title} description={description} />
        ))}

        <p className="my-2 text-gray-800 semiHighlight autoType500">
          <FormattedMessage defaultMessage="Available only to U.S. residents" id="J6QWY9" />
        </p>

        {category === EProtections.TRIP_INSURANCE && (
          <div className="mt-2">
            <Link
              target="_blank"
              href="/help/how-does-trip-insurance-work"
              rel="noopener noreferrer"
              className="body-sm">
              <FormattedMessage defaultMessage="Learn more about trip protection" id="v13+C0" />
              <Icon
                name={CARET_LARGE}
                className="block transform rotate-180 md:hidden"
                width={10}
              />
            </Link>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
