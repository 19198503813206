import React from 'react';
import { useIntl } from 'react-intl';

interface IProps {
  onClearClick: () => void;
}

const DatePickerModalDesktopFooter: React.FC<IProps> = ({ onClearClick }) => {
  const intl = useIntl();

  return (
    <div className="text-right">
      <button
        data-testid="clear-btn"
        onClick={onClearClick}
        className="text-gray-900 underline text autoType300">
        {intl.formatMessage({
          defaultMessage: 'Clear',
          id: '2A47mx',
          description: 'DatePickerModalDesktopFooter - Clear button on Calendar Modal footer',
        })}
      </button>
    </div>
  );
};

export default DatePickerModalDesktopFooter;
