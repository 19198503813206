import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface IProps {
  from?: Date | string;
  to?: Date | string;
  flexibleDays?: number;
  onClearClick?: () => void;
}

const DatePickerModalControls: React.FC<IProps> = ({ from, to, flexibleDays, onClearClick }) => {
  const intl = useIntl();

  const formattedFrom = intl.formatDate(dayjs(from).toLocaleString(), {
    month: 'short',
    day: 'numeric',
  });

  const formattedTo = intl.formatDate(dayjs(to).toLocaleString(), {
    month: 'short',
    day: 'numeric',
  });

  const dateText = `${formattedFrom}${to ? ` - ${formattedTo}` : ''}${
    flexibleDays ? ` (±${flexibleDays})` : ''
  }`;

  return (
    <div className="flex items-center">
      <span data-testid="trip-dates" className="text-gray-800 highlight autoType800">
        {dateText}
      </span>
      {onClearClick && (
        <button
          className="flex items-center ml-5 text-sm text-gray-800 underline border-none underline-offset-2 semiHighlight hover:no-underline"
          onClick={onClearClick}>
          <FormattedMessage
            defaultMessage="Clear dates"
            id="kk6lDe"
            description="DatePickerModalMobileHeader - Clear button on Calendar Modal title"
          />
        </button>
      )}
    </div>
  );
};

export default DatePickerModalControls;
